import React from "react";
import Layout from "../../layouts";
import Seo from "../../components/Seo";
import microApps from "../../images/micro-apps-banner.jpeg";
import IntroBanner from "../../components/IntroBanner";
import team from "../../images/team-pic.png";
import Img from "gatsby-image";
import { graphql } from "gatsby";

const SMSBot = (props) => {
  return (
    <Layout bodyClass="page-blog">
      <Seo
        title="Building Embeddable Apps with Web Components"
        description="We had a recent request from one our clients to build some features into their product. The catch was these had to be made available on websites belonging to their own external customers. Obviously, we didn't have any access or control to the technologies on those websites, which are managed by third parties. Another part of the challenge was that a rich user experience was still expected for the target features."
        image={microApps}
      />
      <IntroBanner
        title="Building Embeddable Apps with Web Components"
        by="by MiniApps Team"
        image={microApps}
      />
      <div className="content">
        <div className="content-body">
          <div className="container pt-4 pt-md-4">
            <div className="row">
              <div className="col">
                <p>
                  We had a recent request from one our clients to build some
                  features into their product. The catch was these had to be made
                  available on websites belonging to their own external customers.
                  Obviously, we didn't have any access or control to the
                  technologies on those websites, which are managed by third
                  parties. Another part of the challenge was that a rich user
                  experience was still expected for the target features.
                </p>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col">
                <h3>What are the Options? </h3>
                <p>
                  This is in fact a common use-case and it has some very common
                  solutions as well. One popular solution is to build a
                  self-contained widget as a web page and embedding it in a
                  different website/applicating using an IFRAME. While this is one
                  of the oldest methods, it is well-supported by web standards, and
                  used by many web widget developers. Of course, this has its own
                  challenges in certain cases, but the simplicity of the method
                  often supplies a good enough solution for many situations.
                </p>
                <p>
                  On the other hand, some developers tend to build web widgets with
                  proprietary methods using JavaScript. When embedded in a host
                  page, these scripts use the ability to manipulate the Browser DOM
                  and render the user experience. While this provides a lot of
                  control, it is a test intensive method to ensure correct
                  functionality without conflicts with the host page.
                </p>
                <p>
                  While our easier choice was IFRAME, we investigated another
                  option, which is a recent web standard called “Web Components”.
                  Web Components allows the definition of a custom HTML tag,
                  including what it looks like and how it behaves. Then it’s just a
                  matter of including a line in the host web page with this new tag;
                  you get your widget displayed on the page seamlessly!
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3>What are Web Components? </h3>
                <p>
                  Web Components is a W3C standard since 2014 (while it was first
                  introduced as far back as 2011). It is composed of three key web
                  technologies
                </p>
                <ol>
                  <li>Custom HTML Elements </li>
                  <li>Shadow DOM </li>
                  <li>HTML Templates. </li>
                </ol>
              </div>
              <div className="col-md-7 pr-md-5 mb-sm-3">
                <Img
                  fluid={props.data.WebComponents.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={true}
                  alt="Keeping Up with Trends"
                />
              </div>
            </div>
            <div className="row pb-4">
              <div className="col">
                <p>
                  As of now all mainstream browsers fully implement these
                  technologies, which means all of them are now Web Component
                  compliant! The combination of these three technologies enabled
                  building standalone, self-contained and reusable components and
                  using them in a web page as a custom HTML element. They are built
                  with HTML, CSS and JavaScript, which runs on the browser engine.
                  Since a web component is built as a custom HTML element, they can
                  be used repeatedly and without conflict with the rest of the web
                  page. Using the Shadow DOM (this is not mandatory) we can make the
                  component isolated from the rest of the parent page and prevent
                  any CSS styles or behaviour affecting the parent page in which the
                  component is embedded. With HTML Templating, not only does the UI
                  code get more structured, but also more extensible.
                  <span className="highlight-link">
                    <a
                      href="https://developer.mozilla.org/en-US/docs/Web/Web_Components"
                      target="_blank"
                    >
                      {" "}
                      See more details
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 pr-md-5 mb-sm-3">
                <Img
                  fluid={props.data.UIControls.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={true}
                  alt="Customer Experience"
                />
              </div>
              <div className="col">
                <h3>Beyond UI Controls </h3>
                <p>
                  In our use-case, we stretched the capabilities of Web Components
                  to build an entire application feature as a widget (or a Mini
                  App). This included the user experience of that feature,
                  client-side logic and communication with backend APIs, all packed
                  into an embeddable component. Web components can be minified,
                  bundled, and hosted as static content. Following this process,
                  they were then used in multiple third-party web sites/applications
                  with just two lines of code.
                </p>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col">
                <p>
                  So, what were the benefits using this approach to build an
                  embeddable widget? First, we were able to include the feature in
                  the component to the parent page more seamlessly without the usual
                  challenges in IFRAME approach such as dealing with the component
                  height. Also, we can use some of the parent page styling inherited
                  by the web component, so that widget looks more native to the
                  theme of the parent page. Since the component is just another
                  custom HTML tag, we were able to provide a standard and familiar
                  programming interface to those who want to customize the widget.
                  This is done by using HTML element attributes and events. Web
                  components can be universally used on many types of client-side
                  web technologies including pure HTML, Angular, React, Vue etc. We
                  wanted to use the same features within our customers core product,
                  and it was just a matter of including the components in the
                  client-side SPA. From a performance point of view, features
                  developed as Web Components were lightweight and fast too!
                </p>
                <p>
                  Does it mean that Web Components can be used to build an app
                  without using usual JavaScript frameworks? Yes and no! It depends
                  on the complexity of the use-case intended. Popular JavaScript
                  frameworks like Angular, React and Vue support common client-side
                  application needs like routing and state management into a great
                  degree. Web Components is a more barebones technology where you
                  need to deal with many other concerns by yourself. So, if it’s a
                  simpler use-case, with less screen transitions, which you need to
                  embed in an outside website or app where you have no direct
                  control, Web Components is a good choice!
                </p>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col">
                <h3>How to build Web Components?</h3>
                <p>
                  There are many ways to build a UI control or a widget using Web
                  Components. The most basic, is to use pure JavaScript, HTML and
                  CSS. But to make things more structured and easier, there are
                  libraries like{" "}
                  <span className="highlight-link">
                    <a href="https://lit.dev" target="_blank">
                      Lit
                    </a>
                  </span>
                  . To accelerate the process, there is tooling available such as{" "}
                  <span className="highlight-link">
                    <a href="https://open-wc.org" target="_blank">
                      {" "}
                      Open Web Components
                    </a>
                  </span>{" "}
                  . Also, it is possible to build Web Components using Angular, Vue
                  or Blazor, which are suitable for more complex use-cases.
                </p>
                <p>
                  Overall, Web Components is a great approach to build
                  standard-based reusable components for the web that can be used
                  with many other web technologies. While its standard use is to
                  build small UI Components, it is also possible to build small
                  application features using Web Components effectively.
                </p>
                {/* <p className="mb-5">
                  <div className="group-pic img-fluid">
                    <img alt="MiniApps Team" src={team} />
                    <div className="utext">
                    The Web Components Team, from left to right: Gayath Sumanarathne, Yesin Singhawansa, Nipunu Wijesinghe (Team Lead) and Nethmini Dulanjalee
                    </div>
                  </div>
                </p> */}
                <p>
                  Discuss & share web components -{" "}
                  <span className="highlight-link">
                    <a href="https://www.webcomponents.org" target="_blank">
                      www.webcomponents.org{" "}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="author mb-5">
        <div className="">
          <div className="row wrap-auther-detail">
            <div className="author-image custom-author-image">
            <img alt="MiniApps Team" src={team} />
            </div>
            <div className="d-flex align-items-center">
              <div className="ml-lg-3 ml-sm-0 detail">
                <h4>
                  <i>Gayath, Yesin, Nipunu and Nethmini</i>
                </h4>
                <span>
                  <i>MiniApps Team</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    WebComponents: file(relativePath: { eq: "webcomponents.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    UIControls: file(relativePath: { eq: "ui-controls.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SMSBot;
